import { HttpClient } from "@angular/common/http";
import { ResellerService } from "../../../@core/services/reseller.service";
import { CouponService, OperatorService, MembershipService, SupportMessageService, PermissionService, CryptoService, EmailsService } from "src/app/@core/services";
import { Component, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import { PagesComponent } from '../../../pages/pages.component';
import { CountUp } from 'countup.js';
import { MenuItem } from 'primeng/api';
import { TranslateService } from "@ngx-translate/core";
import { AppConfigService } from "src/app/app.config.service";
import { ScrollPanel } from 'primeng/scrollpanel';
import { EventEmitter, Output , Renderer2} from '@angular/core';
import { UserType } from "src/app/@core/modals/user-type";

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  model: MenuItem[] = [];
  activeSubmenus: MenuItem[] = [];
  userDisplay: boolean = false;
  @Input() sidebarActive: boolean = false;
  domains: any;
  campaignActive: any = [];
  cryptoActive: any;
  languages = [];
  @Output() sidebarToggled = new EventEmitter<boolean>();
  sidebarExpanded = true;
  isMenuExpanded = true;
  @ViewChild('scrollPanel', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
  sidebarOpen: boolean = false;
  @Output() hideSidebar = new EventEmitter<void>();
  activeSubmenu: any = null;
  showSubMenu?: boolean;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    public router: Router,
    public app: PagesComponent,
    private operatorService: OperatorService,
    private memberService: MembershipService,
    private resellerServrice: ResellerService,
    private couponService: CouponService,
    private supportMessageService: SupportMessageService,
    public translate: TranslateService,
    private configService: AppConfigService,
    private permissionService: PermissionService,
    private cryptoService: CryptoService,
    private emailService: EmailsService,
    public config: AppConfigService,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.layoutMenuScrollerViewChild) {
        this.layoutMenuScrollerViewChild.moveBar();
      }
    }, 100);
  }
  onHideSidebar() {
    this.hideSidebar.emit();
  }
  // toggleSidebar() {
  //   this.sidebarActive = !this.sidebarActive;
  // }

  ngOnInit() {
    const languages = this.config.getLanguages();
    this.languages = languages.map((lang) => {
      return {
        label: lang,
        value: lang
      };
    });
    
    if (this.app.user.userType === UserType.ADMIN) {
      this.loadAdminMenu();
    } else if (this.app.user.userType === UserType.RESELLER) {
      this.resellerServrice.getResellerDomains(this.app.user.id).subscribe(res => {
        this.domains = res.data.length;
      });
  
      this.cryptoService.getCryptoCurrenciesResller(this.app.user.id).subscribe(res => {
        this.cryptoActive = res.data.data ? true : false;
      }, (err) => {
        if (err.error.code === 2002) {
          this.cryptoActive = false;
        }
      });
  
      this.emailService.getResellerGateway(this.app.user.id).subscribe(
        (data) => {
          this.campaignActive = data.data;
        }
      );
  
      setTimeout(() => {
        this.loadResellerMenu();
      }, 1000);
    }
    else if (this.app.user.userType === UserType.EMPLOYEE) {
        this.loadEmployeeMenu();
    }
  }
  toggleSubMenu(menuItem: MenuItem): void {
    // Toggle the submenu visibility
    menuItem.expanded = !menuItem.expanded;
  }
  toggleSubMenu2(submenuItem: MenuItem): void {
    submenuItem.expanded = !submenuItem.expanded;
  }
  onItemClick(menuItem: any) {
    // Perform any other actions when a menu item is clicked
    // This could include navigating to a different route or performing some other action
    // Here, we simply hide the sidebar
    this.onHideSidebar();
  }
  changeLanguage(userLang: any) {
    this.translate.use(userLang);
    localStorage.setItem('lang', userLang);
  }
  isMobileDevice() {
    return window.innerWidth <= 768; // Adjust the width as per your mobile device breakpoint
}
 redirectAndReload(url) {
  if(this.app.user.userType === UserType.EMPLOYEE) {   
    this.sidebarToggled.emit();
  }
  else {
    window.location.href = url; // Redirect to the specified URL
    setTimeout(function() {
        location.reload(); // Reload the page after a delay
    }, 500); // Adjust the delay time as needed
  }
}
public loadResellerMenu() {
  this.model = [
    { label: this.translate.instant('Dashboard'), icon: 'fa fa-fw fa-home', routerLink: ['/pages'] },
    {
      label: this.translate.instant('Hesap'),
      icon: 'fa fa-fw fa-home',
      routerLink: ['/pages/reseller/' + this.app.user.id]
  }
  ];
  this.permissionService.getResellerMenu().subscribe((data) => {
      this.model = [...this.model, ...data.data];

  }, (err) => {

  });
}
     public loadEmployeeMenu() {
      this.model = [
        {
          label: this.translate.instant('Hesap'),
          icon: 'fas fa-user-circle',
          routerLink: ['/pages/employee/' + this.app.user.id]
        },
        {
          label: this.translate.instant('Üyeler'),
          icon: 'fa fa-fw fa-user',
          routerLink: ['/pages/membership']
        },
        {
          label: this.translate.instant('Terminal Actions'),
          icon: 'fas fa-fw fa-cogs',
          routerLink: ['/pages/terminal-actions'],
        },
        {
          label: this.translate.instant('Payout'),
          icon: 'fas fa-fw fa-money-bill',
          target: '_self',
          items: [
            {
              label: this.translate.instant('Coupon Payout'),
              icon: 'fas fa-fw fa-check',
              routerLink: ['/pages/coupon/sport-bet']
            },
            {
                label: this.translate.instant('Receipt Payout'),
                "icon": "fas fa-fw fa-receipt",
                "routerLink": ["/pages/receipt"]
            }
          ]
        }
      ];
    }
  
    private loadAdminMenu() {
      this.model = [
        { label: this.translate.instant('Dashboard'), icon: 'fa fa-fw fa-home', routerLink: ['/pages'] },
      ];
  
      this.permissionService.getMenu().subscribe((data) => {
        this.model = [...this.model, ...data.data];
      }, (err) => {
      });
    }
 

  
}

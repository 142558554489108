import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CouponService {

    constructor(private api: ApiService, private http: HttpClient) {
    }

    getCoupons(query?: any) {
        return this.api.get(this.api.path + '/coupon', query);
    }

    getCoupon(id: any) {
        return this.api.get(this.api.path + '/coupon/' + id);
    }

    cancelCoupon(id: any) {
        return this.api.get(this.api.path + '/coupon/' + id + '/cancel');
    }

    pendingCoupon(id: any) {
        return this.api.get(this.api.path + '/coupon/' + id + '/pending');
    }

    endingCoupon(id: any) {
        return this.api.get(this.api.path + '/coupon/' + id + '/ending');
    }

    getNotSettledOdds(query?: any) {
        return this.api.get(this.api.path + '/coupon/notSettledOdds', query);
    }

    getWaitingCouponMarkets(id: any) {
        return this.api.get(this.api.path + '/coupon/detail/' + id + '/waiting');
    }

    completePay(id: any, payoutPin?: string) {
        return this.api.post(this.api.path + '/coupon/' + id + '/completePay', { payoutPin: payoutPin });
    }

    betSettlement(params: { betResult: number; oddHash: string }) {
        return this.api.post(this.api.path + '/coupon/betSettlement', params);
    }
    
    getBiggestWin(){
        return this.api.get(this.api.path + '/coupon/biggestWin')
    }
    
    initiateRequest(betradarId: string) {
        return this.api.post(this.api.path + '/coupon/reinitialize', {match_id: betradarId});
    }
    
    updateRowStatus(couponId: number, rowId: number, newRowStatus: number) {
        const body = { couponId, rowId, newRowStatus };
        return this.api.post(this.api.path + '/coupon/rowStatus', body);
    }
      
}
